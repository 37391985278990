/*
    Workaround caused by this issue
    https://github.com/angular/angular-cli/issues/11451#issuecomment-424715627
    specific definition to be configured in angular.json and compilerconfig.json
*/
.lg-dropdown-popup--user-menu .lg-dropdown-popup__group:not(:last-of-type) {
  border-top: none !important;
  padding-top: 0 !important;
}
.lg-dropdown-popup--user-menu .lg-dropdown-popup__group:not(:last-of-type) .lg-dropdown-popup__group__name {
  margin-bottom: 0 !important;
}
.lg-dropdown-popup--user-menu .lg-dropdown-popup__group:not(:last-of-type) .lg-dropdown-popup__group__entry > span {
  padding-left: 8px;
}

.button--black {
  background-color: #333333;
  color: #ffffff;
}

.lg-dialog--no-title {
  padding-top: 0;
}
.lg-dialog--no-title .lg-dialog__title {
  display: none;
}

.heading {
  font-size: 24px;
  margin-bottom: 24px;
}

.page-content {
  width: 760px;
  margin-left: 40px;
  padding-top: 36px;
}