.heading {
    font-size: 24px;
    margin-bottom: $spacing5;
}

.page-content {
    width: 760px;
    margin-left: 40px;
    padding-top: $spacing6;
}
