@import "definitions";

.lg-dropdown-popup {
    &--user-menu {
        .lg-dropdown-popup__group:not(:last-of-type) {
            border-top: none !important;
            padding-top: 0 !important;
            .lg-dropdown-popup__group__name {
                margin-bottom: 0 !important;
            }
            .lg-dropdown-popup__group__entry {
                > span {
                    padding-left: $spacing1;
                }
            }
        }
    }
}

.button--black {
    background-color: $gray-color-80;
    color: $white;
}
